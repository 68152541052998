import './App.css';

import React, { useState, useEffect } from "react";
import { createContext, useContext } from 'react';

import Accordion from 'react-bootstrap/Accordion';
import Container from 'react-bootstrap/Container';

import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

import Modal from 'react-bootstrap/Modal';

import Dropdown  from 'react-bootstrap/Dropdown';

import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';


import 'bootstrap/dist/css/bootstrap.min.css';

import { BrowserRouter as Router, Route, Routes, redirect } from "react-router-dom";


import { Chart } from "react-google-charts";
import { ButtonGroup, Col, Row } from 'react-bootstrap';

import { KoFiButton } from "react-kofi";
import "react-kofi/dist/styles.css";


import { Helmet } from 'react-helmet';

import BootstrapSwitchButton from 'bootstrap-switch-button-react' // REMOVE npm i bootstrap-switch-button-react --save --force

const ThemeContext = createContext(null);


function App() {
  const [theme, setTheme] = useState('light');
  const [bgcolor, setBgColor] = useState('whitesmoke');
  const [fontcolor, setFontColor] = useState('black');
  const [tableRowCSS, setTableRowCSS] = useState('tableRowLight')
  const [tableCellCSS, setTableCellCSS] = useState('tableCellLight')

  return (
    <Router>
      <Helmet>
        <title>Tekken 8 Tournament Ratings</title>
      </Helmet>
      <main>
      <div className="App" style={{backgroundColor: bgcolor}}>
        <NavBarHeading theme_context={theme}/>
        <ThemeContext.Provider value={theme}>
        <Form/>
        <Form style={{color:fontcolor, display: 'flex', 'justifyContent': 'right'}}>
          <Form.Check // prettier-ignore
            type="switch"
            id="custom-switch"
            label="Dark Mode"

            onChange={(e) => {
              setTheme(e.target.checked ? 'dark' : 'light');
              setBgColor(e.target.checked ? 'black' : 'whitesmoke');
              setTableRowCSS(e.target.checked ? 'tableRowDark': 'tableRowLight');
              setFontColor(e.target.checked ? 'whitesmoke' : 'black');
              setTableCellCSS(e.target.checked ? 'tableCellDark' : 'tableCellLight');
            }}
          />
        </Form>
      </ThemeContext.Provider>
      </div>
        <Routes>
          <Route path="/" element={<RatingsPage
            api_url='https://tristanmelton.com:7777'
            site_url='https://tekkenratings.tristanmelton.com'
            page_title='Tekken 8 Tournament Ratings'
            game_type='t8'
            theme_context={theme}
            bg_color = {bgcolor}
            font_color = {fontcolor}
            tablerow_css = {tableRowCSS}
            tablecell_css = {tableCellCSS}
            />} />
        </Routes>
      </main>
    </Router>
  );


  //TODO: Update this
  if (window.location.host.split(".")[0] === "tekkenratings") {
    return (
      <Router>
        <Helmet>
          <title>Tekken 8 Tournament Ratings</title>
        </Helmet>
        <main>
        <div className="App" style={{backgroundColor: bgcolor}}>
          <NavBarHeading theme_context={theme}/>
          <ThemeContext.Provider value={theme}>
          <Form/>
          <Form style={{color:fontcolor, display: 'flex', 'justifyContent': 'right'}}>
            <Form.Check // prettier-ignore
              type="switch"
              id="custom-switch"
              label="Dark Mode"

              onChange={(e) => {
                setTheme(e.target.checked ? 'dark' : 'light');
                setBgColor(e.target.checked ? 'black' : 'whitesmoke');
                setTableRowCSS(e.target.checked ? 'tableRowDark': 'tableRowLight');
                setFontColor(e.target.checked ? 'whitesmoke' : 'black');
                setTableCellCSS(e.target.checked ? 'tableCellDark' : 'tableCellLight');
              }}
            />
          </Form>
        </ThemeContext.Provider>
        </div>
          <Routes>
            <Route path="/" element={<RatingsPage
              api_url='https://tristanmelton.com:7777'
              site_url='https://tekkenratings.tristanmelton.com'
              page_title='Tekken 8 Tournament Ratings'
              game_type='t8'
              theme_context={theme}
              bg_color = {bgcolor}
              font_color = {fontcolor}
              tablerow_css = {tableRowCSS}
              tablecell_css = {tableCellCSS}
              />} />
          </Routes>
        </main>
      </Router>
    );
  }
  else if (window.location.host.split(".")[0] === "2xkoratings") {
    return (
      <Router>
        <Helmet>
          <title>2XKO Offline Ratings</title>
        </Helmet>
        <main>
        <div className="App" style={{backgroundColor: bgcolor}}>
          <NavBarHeading theme_context={theme}/>
          <ThemeContext.Provider value={theme}>
          <Form/>
          <Form style={{color:fontcolor, display: 'flex', 'justifyContent': 'right'}}>
            <Form.Check // prettier-ignore
              type="switch"
              id="custom-switch"
              label="Dark Mode"

              onChange={(e) => {
                setTheme(e.target.checked ? 'dark' : 'light');
                setBgColor(e.target.checked ? 'black' : 'whitesmoke');
                setTableRowCSS(e.target.checked ? 'tableRowDark': 'tableRowLight');
                setFontColor(e.target.checked ? 'whitesmoke' : 'black');
                setTableCellCSS(e.target.checked ? 'tableCellDark' : 'tableCellLight');
              }}
            />
          </Form>
        </ThemeContext.Provider>
        </div>

          <Routes>
            <Route path="/" element={<RatingsPage
              api_url='https://tristanmelton.com:7777'
              site_url='https://2xkoratings.tristanmelton.com'
              page_title='2XKO Tournament Ratings'
              game_type='2xko'
              theme_context={theme}
              bg_color = {bgcolor}
              font_color = {fontcolor}
              tablerow_css = {tableRowCSS}
              tablecell_css = {tableCellCSS}
              />} />
          </Routes>
        </main>
      </Router>
    );
  }
  else if (window.location.host.split(".")[0] === "sfratings") {
    return (
      <Router>
        <Helmet>
          <title>SF6 Offline Ratings</title>
        </Helmet>
        <main>
        <div className="App" style={{backgroundColor: bgcolor}}>
          <NavBarHeading theme_context={theme}/>
          <ThemeContext.Provider value={theme}>
          <Form/>
          <Form style={{color:fontcolor, display: 'flex', 'justifyContent': 'right'}}>
            <Form.Check // prettier-ignore
              type="switch"
              id="custom-switch"
              label="Dark Mode"

              onChange={(e) => {
                setTheme(e.target.checked ? 'dark' : 'light');
                setBgColor(e.target.checked ? 'black' : 'whitesmoke');
                setTableRowCSS(e.target.checked ? 'tableRowDark': 'tableRowLight');
                setFontColor(e.target.checked ? 'whitesmoke' : 'black');
                setTableCellCSS(e.target.checked ? 'tableCellDark' : 'tableCellLight');
              }}
            />
          </Form>
        </ThemeContext.Provider>
        </div>

          <Routes>
            <Route path="/" element={<RatingsPage
              api_url='https://tristanmelton.com:7777'
              site_url='https://sfratings.tristanmelton.com'
              page_title='Street Fighter 6 Tournament Ratings'
              game_type='sf6'
              theme_context={theme}
              bg_color = {bgcolor}
              font_color = {fontcolor}
              tablerow_css = {tableRowCSS}
              tablecell_css = {tableCellCSS}
              />} />
          </Routes>
        </main>
      </Router>
    );
  }
  else {
    return (
      <Router>
        <Helmet>
          <title>Tekken 8 Tournament Ratings</title>
        </Helmet>
        <main>
          <div className="App">
            <NavBarHeading/>
          </div>
          <Routes>
            <Route path="/" element={<RatingsPage
              api_url='https://tristanmelton.com:7777'
              site_url='https://tekkenratings.tristanmelton.com'
              page_title='Tekken 8 Tournament Ratings'
              game_type='t8'
              />} />
          </Routes>
        </main>
      </Router>
    );
  }
}


function NavBarHeading(props) {
  return (
    <Navbar expand="lg" className="bg-body-tertiary" data-bs-theme={props.theme_context} >
      <Container fluid>
        <Navbar.Brand href="https://tristanmelton.com/">Tristan Melton</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link href="https://tristanmelton.com/research">Research</Nav.Link>
            <NavDropdown title="Personal Projects" id="basic-nav-dropdown">
              <NavDropdown.Item href="https://tekkenratings.tristanmelton.com">Tekken 8 Tournament Ratings</NavDropdown.Item>
              <NavDropdown.Item href="https://sfratings.tristanmelton.com">Street Fighter 6 Tournament Ratings</NavDropdown.Item>
              <NavDropdown.Item href="https://tristanmelton.com/faradayfightstick">Faraday Fightstick</NavDropdown.Item>
            </NavDropdown>
            <Nav.Link href="https://tristanmelton.com/contact-me">Contact Me</Nav.Link>
            <NavDropdown title="Wikis" id="basic-nav-dropdown">
              <NavDropdown.Item href="https://tristanmelton.com/dokuwiki/doku.php?id=start">Fighting Game Wiki</NavDropdown.Item>
              <NavDropdown.Item href="https://tristanmelton.com/wong_lab/doku.php?id=start">Frequency Comb Subgroup Wiki</NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

function RatingsPage(props) {
  const pageInfo = {
    url: props.site_url,
    page_title: props.page_title,
    game_type: props.game_type,
  };
  var bgcolor = props.bg_color;
  const [ formData, setFormData ] = useState({
    slug: '',
  })
  const [ ratingData, setRatingData ] = useState({
    rating: '',
    deviation: '',
    gamertag: '',
    top_pct: '',
    winrate: '',
    regional_percent: '',
    regional_ranking: '',
    global_ranking: '',
  })
  const [ ratingOfflineData, setRatingOfflineData ] = useState({
    rating: '',
    deviation: '',
    gamertag: '',
    top_pct: '',
    winrate: '',
    regional_percent: '',
    regional_ranking: '',
    global_ranking: '',
  })

  const [rData, setRData] = useState({
    rating: '',
    deviation: '',
    gamertag: '',
    top_pct: '',
    winrate: '',
    regional_percent: '',
    regional_ranking: '',
    global_ranking: '',
  })

  const [opponentSlugs, setOpponentSlugs] = useState([])
  const [opponentOfflineSlugs, setOpponentOfflineSlugs] = useState([])
  const [tournamentIds, setTournamentIds] = useState([])

  const [ matchHistoryData, setHistoryData ] = useState([])
  const [ matchTableData, setTableData ] = useState([["Match Time", "Opponent", "Final Rating", "Rating Change", "Deviation", "Tournament Name"]])
  const [ headToHeadData, setHeadToHeadData ] = useState([["Opponent", "Sets Won", "Total Sets", "Win Rate"]])
  const [ matchHistoryOfflineOnlyData, setHistoryOfflineOnlyData ] = useState([])
  const [ matchTableOfflineOnlyData, setTableOfflineOnlyData ] = useState([["Match Time", "Opponent", "Final Rating", "Rating Change", "Deviation", "Tournament Name"]])
  const [ currMatchData, setCurrMatchData] = useState([["Match Time", "Opponent", "Final Rating", "Rating Change", "Deviation", "Tournament Name"]])
  const [offlineToggle, setOfflineToggle] = useState(false)
  
  const [ ratingDist, setRatingDist ] = useState([
    ["Rating", "Count"],
    [600, 0],
    [650, 0],
    [700, 0],
    [750, 0],
    [800, 0],
    [850, 0],
    [900, 0],
    [950, 0],
    [1000, 0],
    [1050, 0],
    [1100, 0],
    [1150, 0],
    [1200, 0],
    [1250, 0],
    [1300, 0],
    [1350, 0],
    [1400, 0],
    [1450, 0],
    [1500, 0],
    [1550, 0],
    [1600, 0],
    [1650, 0],
    [1700, 0],
    [1750, 0],
    [1800, 0],
    [1850, 0],
    [1900, 0],
    [1950, 0],
    [2000, 0],
//    [2050, 0],
//    [2100, 0],
//    [2150, 0],
//    [2200, 0],
//    [2250, 0],
//    [2300, 0],
//    [2350, 0],
  ])
  const [ ratingDistPct, setRatingDistPct ] = useState([
    ["Rating", "Percent"],
    [600, 0],
    [650, 0],
    [700, 0],
    [750, 0],
    [800, 0],
    [850, 0],
    [900, 0],
    [950, 0],
    [1000, 0],
    [1050, 0],
    [1100, 0],
    [1150, 0],
    [1200, 0],
    [1250, 0],
    [1300, 0],
    [1350, 0],
    [1400, 0],
    [1450, 0],
    [1500, 0],
    [1550, 0],
    [1600, 0],
    [1650, 0],
    [1700, 0],
    [1750, 0],
    [1800, 0],
    [1850, 0],
    [1900, 0],
    [1950, 0],
    [2000, 0],
//    [2050, 0],
//    [2100, 0],
//    [2150, 0],
//    [2200, 0],
//    [2250, 0],
//    [2300, 0],
//    [2350, 0],
  ])
  const [ devDist, setDevDist ] = useState([
    ["Deviation", "Count"],
    [30, 0],
    [40, 0],
    [50, 0],
    [60, 0],
    [70, 0],
    [80, 0],
    [90, 0],
    [100, 0],
    [110, 0],
    [120, 0],
    [130, 0],
    [140, 0],
    [150, 0],
    [160, 0],
    [170, 0],
    [180, 0],
    [190, 0],
    [200, 0],
    [210, 0],
  ])

  const [ leaderboardTableData, setLeaderboardTableData ] = useState([['Name', 'Rating']])
  const [ countryLeaderboardTableData, setCountryLeaderboardTableData ] = useState([['Name', 'Rating']])

  const [countrySearchTerm, setCountrySearchTerm] = useState("");
  const [currCountry, setCurrCountry] = useState("");
  const [countries, setCountries] = useState([]);

  const [eloColorCheck, setEloColorCheck] = useState([]);
  const [oppShow, setOppShow] = useState(false);
  const [oppData, setOppData ] = useState({
    rating: '',
    deviation: '',
    gamertag: '',
    top_pct: '',
    winrate: '',
    regional_percent: '',
    regional_ranking: '',
    global_ranking: '',
  })
  const [h2hModalShow, setH2HModalShow] = useState(false);
  const [h2hOppModalData, setH2HOppModalData ] = useState({
    rating: '',
    deviation: '',
    gamertag: '',
    top_pct: '',
    winrate: '',
    regional_percent: '',
    regional_ranking: '',
    global_ranking: '',
  })
  const [ h2hModalMatchData, setH2HModalMatchData ] = useState([["Match Time", "Opponent", "Final Rating", "Rating Change", "Deviation", "Tournament Name"]])
  const [h2hModalWinRate, setH2HModalWinRate] = useState();

  const [key, setKey] = useState('leaderboards');

  const handleDropdownSelect = (eventKey, event) => {
    setCurrCountry(eventKey);
    fetch(props.api_url + '/leaderboards/countries/'+eventKey+'?game='+props.game_type, {})
    .then(res => {
      if (res.ok) {
        var tbldata = [["Name", "Rating"]];
        res.json().then((data) => {
          var names = data['names'];
          var ratings = data['rating'];
  
          for (let i = 0; i < ratings.length; i++) {    
            tbldata.push([names[i], ratings[i]])
          }
          console.log("Setting leaderboard data")
          setCountryLeaderboardTableData(tbldata)
        }) 
      } else {
      }    
    })

  };
  const filteredOptions = Object.values(countries).filter((country) =>
    country.toLowerCase().startsWith(countrySearchTerm.toLowerCase())
  );

  const ratingDistOptions = {
    width: '600px',
    height: '600px',
    title: 'Rating Distribution',
    backgroundColor: props.bg_color,
    titleTextStyle: {color: props.font_color},
    hAxis: {
        title: 'Rating',
        textStyle:{color: props.font_color},
        titleTextStyle: {color: props.font_color}
    },
    vAxis: {
        title: 'Count',
        textStyle:{color: props.font_color},
        titleTextStyle: {color: props.font_color}  
    },
    colors: ['#007bff'],
    legend: {position: 'none'},
    explorer: {
      actions: ["dragToZoom", "rightClickToReset"],
      //axis: "horizontal",
      //keepInBounds: true
    },
  };
  const ratingDistPctOptions = {
    title: 'Rating Distribution, Percentage',
    backgroundColor: props.bg_color,
    titleTextStyle: {color: props.font_color},
    hAxis: {
        title: 'Rating',
        textStyle:{color: props.font_color},
        titleTextStyle: {color: props.font_color}  
    },
    vAxis: {
        title: 'Percent',
        textStyle:{color: props.font_color},
        titleTextStyle: {color: props.font_color}  
    },
    colors: ['#007bff'],
    legend: {position: 'none'},
    explorer: {
      actions: ["dragToZoom", "rightClickToReset"],
      //axis: "horizontal",
      //keepInBounds: true
    },
  };
  const matchHistoryOptions = {
    curveType: "function",
    legend: {position: 'none', textStyle: {color: props.font_color}},
    chartArea: {
      height: '100%',
      width: '100%',
      top: 48,
      left: 100,
      right: 100,
      bottom: 48
    },
    hAxis: {
      title: "Set",
      textStyle:{color: props.font_color},
      titleTextStyle: {color: props.font_color}
    },
    vAxis: {
      title: "Rating",
      textStyle:{color: props.font_color},
      titleTextStyle: {color: props.font_color}
    },
    pointSize: 6,
    colors: ['#000000'],
    explorer: {
      actions: ["dragToZoom", "rightClickToReset"],
      //axis: "horizontal",
      //keepInBounds: true
    },
    annotations: {
      stem: {
        length: 30
      }
    },
    backgroundColor: props.bg_color,
    colors: [props.font_color],
    height: 400,
    width: 900,
  };
  const matchTableOptions = {
    allowHtml: true,
    showRowNumber: true,
    backgroundColor: props.bg_color,
    chartArea: {
      height: '100%',
      width: '100%',
      top: 48,
      left: 48,
      right: 16,
      bottom: 48
    },
    cssClassNames: { 
      headerRow: props.tablecell_css,
      tableRow: props.tablerow_css,
      oddTableRow: props.tablerow_css,
      selectedTableRow: 'selectedTableRow',
      hoverTableRow: 'hoverTableRow',
      headerCell: props.tablecell_css,
      tableCell: props.tablecell_css,
      rowNumberCell: 'rowNumberCell'
    },   
    sort: 'enable',
    height: '100%',
    width: '100%',
  };
  const h2hTableOptions = {
    allowHtml: true,
    showRowNumber: false,
    chartArea: {
      height: '100%',
      width: '100%',
      top: 48,
      left: 48,
      right: 16,
      bottom: 48
    },
    cssClassNames: { 
      headerRow: props.tablecell_css,
      tableRow: props.tablerow_css,
      oddTableRow: props.tablerow_css,
      selectedTableRow: 'selectedTableRow',
      hoverTableRow: 'hoverTableRow',
      headerCell: props.tablecell_css,
      tableCell: props.tablecell_css,
      rowNumberCell: 'rowNumberCell'
    },   

    sort: 'enable',
    height: '100%',
    width: '100%',
  };
  const h2hMatchHistoryOptions = {
    allowHtml: true,
    showRowNumber: false,
    chartArea: {
      height: '100%',
      width: '100%',
      top: 48,
      left: 48,
      right: 16,
      bottom: 48
    },
    cssClassNames: { 
      headerRow: props.tablecell_css,
      tableRow: props.tablerow_css,
      oddTableRow: props.tablerow_css,
      selectedTableRow: 'selectedTableRow',
      hoverTableRow: 'hoverTableRow',
      headerCell: props.tablecell_css,
      tableCell: props.tablecell_css,
      rowNumberCell: 'rowNumberCell'
    },   
  
    sort: 'enable',
    height: '100%',
    width: '100%',
  };
  const devDistOptions = {
    title: 'Deviation Distribution',
    backgroundColor: props.bg_color,
    titleTextStyle: {color: props.font_color},
    hAxis: {
        title: 'Rating',
        textStyle:{color: props.font_color},
        titleTextStyle: {color: props.font_color}  
    },
    vAxis: {
        title: 'Count',
        textStyle:{color: props.font_color},
        titleTextStyle: {color: props.font_color}  
    },
    colors: ['#007bff'],
    legend: {position: 'none'},
    explorer: {
      actions: ["dragToZoom", "rightClickToReset"],
      //axis: "horizontal",
      //keepInBounds: true
    },
  };
  const leaderboardTableOptions = {
    allowHtml: true,
    showRowNumber: true,
    chartArea: {
      height: '100%',
      width: '100%',
      top: 48,
      left: 48,
      right: 16,
      bottom: 48
    },
    height: '100%',
    width: '100%',
    backgroundColor: props.bg_color,
    cssClassNames: { 
      headerRow: props.tablecell_css,
      tableRow: props.tablerow_css,
      oddTableRow: props.tablerow_css,
      selectedTableRow: 'selectedTableRow',
      hoverTableRow: 'hoverTableRow',
      headerCell: props.tablecell_css,
      tableCell: props.tablecell_css,
      rowNumberCell: props.tablecell_css
    },   

  };
  const countryLeaderboardTableOptions = {
    allowHtml: true,
    showRowNumber: true,
    chartArea: {
      height: '100%',
      width: '100%',
      top: 48,
      left: 48,
      right: 16,
      bottom: 48
    },
    height: '100%',
    width: '100%',
    backgroundColor: props.bg_color,
    cssClassNames: { 
      headerRow: props.tablecell_css,
      tableRow: props.tablerow_css,
      oddTableRow: props.tablerow_css,
      selectedTableRow: 'selectedTableRow',
      hoverTableRow: 'hoverTableRow',
      headerCell: props.tablecell_css,
      tableCell: props.tablecell_css,
      rowNumberCell: 'rowNumberCell'
    },   

  };
  const leaderboardBarOptions = {
    width: '600px',
    height: '600px',
    title: 'Leaderboard',
    backgroundColor: props.bg_color,
    titleTextStyle: {color: props.font_color},

    hAxis: {
      textStyle:{color: props.font_color},
      titleTextStyle: {color: props.font_color},
      labels: {
        style: {
            textOverflow: 'none',
            textStyle:{color: props.font_color},
        }
    } 

    },
    vAxis: {
        title: 'Rating',
        textStyle:{color: props.font_color},
        titleTextStyle: {color: props.font_color} ,
    },
    colors: ['#007bff'],
    legend: {position: 'none'},
    explorer: {
      actions: ["dragToZoom", "rightClickToReset"],
      //axis: "horizontal",
      //keepInBounds: true
    },
  };

  const matchTableFormatter = [
    {
       type: "ArrowFormat",
      column: 3,
    },
  ];
  const headToHeadFormatter = [
    {
      type: "NumberFormat",
      column: 3,
      options: {
         suffix: "%",
       }
    },
  ];

  function GetPointColors(ratings, tournament_ids) {
    var retdata = [];
    if(eloColorCheck === true) {
      var current_tournament = -1;
      var curr_color = 'rgb(0,0,0)';
      for (let i = 0; i < ratings.length; i++) {
        if(tournament_ids[i] !== current_tournament) {
          curr_color = 'rgb('+Math.floor(Math.random() * 256)+','+Math.floor(Math.random() * 256)+','+ Math.floor(Math.random() * 256)+')';
          current_tournament = tournament_ids[i];
        }
        retdata.push('point { size: 6; shape-type: circle; fill-color:' + curr_color + '; }');
      }
    } else {
      var start_rating = 1300;
      for (let i = 0; i < ratings.length; i++) {

        var delta = ratings[i]-start_rating;
      
        if(delta > 0) {
          retdata.push('point { size: 6; shape-type: circle; fill-color:rgb(20, 124, 63); }');
        }
        else {
          retdata.push('point { size: 6; shape-type: circle; fill-color:rgb(124, 20, 20); }');
        }
        start_rating = ratings[i];
      }
    }
    return retdata; 
  }
  function GetPointColorsIsolated(ratings, tournament_ids, do_color) {
    var retdata = [];
    if(do_color === true) {
      var current_tournament = -1;
      var curr_color = 'rgb(0,0,0)';
      for (let i = 0; i < ratings.length; i++) {
        if(tournament_ids[i] !== current_tournament && tournament_ids[i] != null) {
          curr_color = 'rgb('+Math.floor(Math.random() * 256)+','+Math.floor(Math.random() * 256)+','+ Math.floor(Math.random() * 256)+')';
          current_tournament = tournament_ids[i];
        }

        retdata.push('point { size: 6; shape-type: circle; fill-color:' + curr_color + '; }');
      }
    } else {
      var start_rating = 1300;
      for (let i = 0; i < ratings.length; i++) {

        var delta = ratings[i]-start_rating;
      
        if(delta > 0) {
          retdata.push('point { size: 6; shape-type: circle; fill-color:rgb(20, 124, 63); }');
        }
        else {
          retdata.push('point { size: 6; shape-type: circle; fill-color:rgb(124, 20, 20); }');
        }
        start_rating = ratings[i];
      }
    }
    return retdata; 
  }

  async function RatingSubmit(e) {
    setKey('match_history');
    var res = await fetch(props.api_url + '/rating/'+formData["slug"]+'?game='+props.game_type, {})
    if (res.ok) {
      var data = await res.json();
      setRatingData({
            rating: data["rating"],
            deviation: data["deviation"],
            gamertag: data["gamertag"],
            top_pct: Math.trunc((data["top_percent"]*10000))/100,
            winrate: Math.trunc((data["winrate"]*10000))/100,
            regional_percent: Math.trunc((data["regional_percent"]*10000))/100,
            regional_ranking: data["regionalranking"],
            global_ranking: data["globalranking"],
      }) 
      setRatingOfflineData({
            rating: data["offline_rating"],
            deviation: data['offline_deviation'],
            gamertag: data['gamertag'],
            top_pct: Math.trunc((data['top_percent_offline']*10000))/100,
            winrate: Math.trunc((data["winrate_offline"]*10000))/100,
            regional_percent: Math.trunc((data['regional_percent_offline']*10000))/100,
            regional_ranking: data['regional_ranking_offline'],
            global_ranking: data['global_ranking_offline'],
      })
    }
    else {
        alert("Could not find user")
  }
  var res = await fetch(props.api_url + '/match_history/'+formData["slug"]+'?game='+props.game_type, {})
  if (res.ok) {
    var retdata = [["Match", "Rating", "Tournament Name", {'type': 'string', 'role': 'style'}]];
    var tbldata = [["Match Time", "Opponent", "Final Rating", "Rating Change", "Deviation", "Tournament Name"]];
    var h2hdata = [["Opponent", "Sets Won", "Total Sets", "Win Rate"]];

    var retdata_offline = [["Match", "Rating", "Tournament Name", {'type': 'string', 'role': 'style'}]];
    var tbldata_offline = [["Match Time", "Opponent", "Final Rating", "Rating Change", "Deviation", "Tournament Name"]];
    var oppslugs_offline = [];
    var temph2h = {};
    var data = await res.json();
        
    var ratings = data['ratings'];
    var devs = data['deviations'];
    var times = data['match_time'];
    var usr = data['other_player'];
    var slugs = data['other_slug'];
    var wins = data['wins'];
    var tourney_ids = data['tourney_id'];
    var tourney_names = data['tourney_name'];
    var is_online = data['is_online'];

    var ratings_offline = data['offline_ratings'];
    var devs_offline = data['offline_devs'];
    setTournamentIds(tourney_ids);
        //setRatings(ratings);
        //setTournamentNames(tourney_names);
    setOpponentSlugs(slugs);

    var colors = GetPointColors(ratings, tourney_ids);

    var currTournament = null;
    var start_rating = 1300;
    var i_offline = 0;
    for (let i = 0; i < ratings.length; i++) {
      var tourney_name = null;
      if(tourney_names[i] !== currTournament) {
        tourney_name = tourney_names[i];
      }
      currTournament = tourney_names[i];
      var date = new Date(times[i]*1000);
      var delta = ratings[i]-start_rating;
      retdata.push([i+1, ratings[i], tourney_name, colors[i]]);
      tbldata.push([date.toLocaleString('en-US'), usr[i], ratings[i], delta, devs[i], tourney_names[i]]);
        if(!is_online[i]){
        retdata_offline.push([i_offline, ratings_offline[i], tourney_name, colors[i]]);
        tbldata_offline.push([date.toLocaleString('en-US'), usr[i], ratings_offline[i], delta, devs_offline[i], tourney_names[i]]);
        oppslugs_offline.push(slugs[i]);
        i_offline++;
      }
      if(!(usr[i] in temph2h)) {
        temph2h[usr[i]] = [parseInt(wins[i]), 1];
      } 
      else {
        var temp = temph2h[usr[i]];
        temp[0] += parseInt(wins[i]);
        temp[1] += 1;
        temph2h[usr[i]] = temp;
      }
      start_rating = ratings[i];
    }
    console.log("Setting match history");
  
    setHistoryData(retdata);
    setTableData(tbldata);
    setHistoryOfflineOnlyData(retdata_offline);
    setTableOfflineOnlyData(tbldata_offline);
    setOpponentOfflineSlugs(oppslugs_offline);
    if(offlineToggle) {
      RenderMatchHistory(data = retdata_offline);
    } else {
      RenderMatchHistory(data = retdata);
    }
    if(offlineToggle) {
      setCurrMatchData(tbldata_offline);
    }
    else {
      setCurrMatchData(tbldata);
    }
    for(const [key,value] of Object.entries(temph2h)) {
      h2hdata.push([key, value[0], value[1], Math.trunc(((parseFloat(value[0])/parseFloat(value[1]))*10000))/100]);
    }
    h2hdata.sort(function(a,b) {
      return b[2]-a[2]
    });
    setHeadToHeadData(h2hdata);
  } 
  else {
  }
  }

  function HandleSlugChange(e) {
    const key = e.target.name;
    const value = e.target.value;
    setFormData({...formData, [key]: value})
  }
  async function HandleLeaderboardRegionChange(e) {
    const value = e.target.value;
    var res = await fetch(props.api_url + '/leaderboards/'+value+'?game='+props.game_type+'&offline='+offlineToggle, {});
      if (res.ok) {
        var tbldata = [["Name", "Rating"]];
        var top10data = [['Name', 'Rating']];
        try {
          var data = await res.json();
          var names = data['names'];
          var ratings = data['rating'];
  
          for (let i = 0; i < ratings.length; i++) {    
            tbldata.push([names[i], ratings[i]])
            if(i < 10) {
              top10data.push([names[i], ratings[i]]);
            }

          }
          console.log("Setting leaderboard data")
          setLeaderboardTableData(tbldata);
        }
        catch {

        }
      } else {
      }  
  }
  function HandleMatchHistoryColorToggleChange(e) {
    const value = e.target.checked;
    var retdata = [["Match", "Rating", "Tournament Name", {'type': 'string', 'role': 'style'}]];
    var retdata_offline = [["Match", "Rating", "Tournament Name", {'type': 'string', 'role': 'style'}]];

    setEloColorCheck(value);
    if(tournamentIds === undefined || tournamentIds === 0) {

    }
    else {
      var ratings = [];
      var ratings_offline = [];
      var tourneyIds = [];
      var tourneyIds_offline = [];
      
      ratings_offline = matchHistoryOfflineOnlyData.map(x => x[1]);
      ratings_offline.shift();
      tourneyIds_offline = matchHistoryOfflineOnlyData.map(x => x[2]);
      tourneyIds_offline.shift();
      
      ratings = matchHistoryData.map(x => x[1]);
      ratings.shift();
      tourneyIds = matchHistoryData.map(x => x[2]);
      tourneyIds.shift();

      var colors = GetPointColorsIsolated(ratings, tourneyIds,value);
      var colors_offline = GetPointColorsIsolated(ratings_offline, tourneyIds_offline, value);

      var currTournament = null;
      for (let i = 0; i < ratings.length; i++) {
        var tourney_name = null;
        if(tourneyIds[i] !== currTournament) {
          tourney_name = tourneyIds[i];
        }
        currTournament = tourneyIds[i];
        retdata.push([i+1, ratings[i], tourney_name, colors[i]]);
      }
      currTournament = null;
      for (let i = 0; i < ratings_offline.length; i++) {
        var tourney_name = null;
        if(tourneyIds_offline[i] !== currTournament) {
          tourney_name = tourneyIds_offline[i];
        }
        currTournament = tourneyIds_offline[i];
        retdata_offline.push([i+1, ratings_offline[i], tourney_name, colors_offline[i]]);
      }
      setHistoryOfflineOnlyData(retdata_offline);
      setHistoryData(retdata);

      if(offlineToggle) {
        RenderMatchHistory(retdata_offline);
      }
      else {
        RenderMatchHistory(retdata);
      }
    }
  }
  function HandleMatchHistoryOfflineToggleChange(e) {
    const value = e.target.checked;
    setOfflineToggle(value);
    if(value) {
      RenderMatchHistory(matchHistoryOfflineOnlyData);
      setCurrMatchData(matchTableOfflineOnlyData);
      setRData(ratingOfflineData);
    }
    else {
      RenderMatchHistory(matchHistoryData);
      setCurrMatchData(matchTableData);
      setRData(ratingData);
    }
    UpdateDistributions();
    RenderCompStats();
//      RenderMatchHistory(retdata);
  }

  function RenderMatchHistory(data = null) {
    var matchData = null;
    if(data !== null && data.constructor === Array && data.length !== 0) {
      matchData = window.google.visualization.arrayToDataTable(data);
    }
    else{
      if(offlineToggle) {
        if(matchHistoryOfflineOnlyData.length !== 0)
          matchData = window.google.visualization.arrayToDataTable(matchHistoryOfflineOnlyData);
        else
          return;
      }
      else {
        if(matchHistoryData.length !== 0)
          matchData = window.google.visualization.arrayToDataTable(matchHistoryData);
        else
          return;
      }
    }
    var view = new window.google.visualization.DataView(matchData);
    view.setColumns([0, 1, 3, {
        calc: function (dt, row) {
          if (dt.getValue(row, 2) !== null) {
            return dt.getFormattedValue(row, 2);
          }
          return null;
        },
        sourceColumn: 2,
        type: "string",
        role: "annotation"

    },]);
    const ratingHistoryChart = new window.google.visualization.LineChart(document.getElementById('matchLineChart'));
    ratingHistoryChart.draw(view, matchHistoryOptions);
  }
  function RenderCompStats() {
    const dataRating = new window.google.visualization.DataTable();
    dataRating.addColumn('number', 'Rating');
    dataRating.addColumn('number', 'Count');
    dataRating.addRows(ratingDist.slice(1));
    const ratingDistChart = new window.google.visualization.ColumnChart(document.getElementById('ratingDistChart'));

    ratingDistChart.draw(dataRating, ratingDistOptions);

    const dataRatingPct = new window.google.visualization.DataTable();
    dataRatingPct.addColumn('number', 'Rating');
    dataRatingPct.addColumn('number', 'Percent');
    dataRatingPct.addRows(ratingDistPct.slice(1));
    const ratingDistPctChart = new window.google.visualization.ColumnChart(document.getElementById('ratingDistPctChart'));
    ratingDistPctChart.draw(dataRatingPct, ratingDistPctOptions);

    const dataDev = new window.google.visualization.DataTable();
    dataDev.addColumn('number', 'Rating');
    dataDev.addColumn('number', 'Percent');
    dataDev.addRows(devDist.slice(1));
    const devDistChart = new window.google.visualization.ColumnChart(document.getElementById('devDistChart'));
    devDistChart.draw(dataDev, devDistOptions);
  }
  async function RenderLeaderboard(data = null) {
    var matchData = null;
    if(data !== null && data.constructor === Array && data.length !== 0) {
      matchData = window.google.visualization.arrayToDataTable(data);
    }
    else{
        if(leaderboardTableData.length !== 1) {
          var d = leaderboardTableData.slice(0, 11);
          matchData = window.google.visualization.arrayToDataTable(d);
        }
        else
          return;
    }

    const leaderboardChart = new window.google.visualization.ColumnChart(document.getElementById('leaderboardChart'));

    leaderboardChart.draw(matchData, leaderboardBarOptions);  

  }

  function RatingDistrib() {
    fetch(props.api_url + '/statistics/bucketed_rating?game='+props.game_type+'&offline='+offlineToggle, {
    })
    .then(res => {
      if (res.ok) {
        var retdata = [["A", "B"]];
        var pctdata = [["a", "b"]];
        var sum = 0;
        var runningpct = 0;
        res.json().then((data) => {
          var edges = data['bin_edges'];
          var counts = data['bin_counts'];
          for (let i = 0; i < ratingDist.length; i++) {
            retdata.push([edges[i], counts[i]]);
            sum += counts[i];
          }
          for (let i = 0; i < ratingDist.length; i++) {
            pctdata.push([edges[i], 100-100*(runningpct + parseFloat(counts[i])/sum)])
            runningpct = runningpct + parseFloat(counts[i])/sum;
          }
          console.log("Setting rating stat")
          const newData = ratingDist.map((entry, index) => {
            if (index === 0) {
                return entry;
            } else {
                const newProfit = retdata[index][1];
                return [entry[0], newProfit];
            }
          });
          const newDataPct = ratingDistPct.map((entry, index) => {
            if (index === 0) {
                return entry;
            } else {
                const pctchange = pctdata[index][1];
                return [entry[0], pctchange];
            }
          });
          setRatingDist(newData)
          setRatingDistPct(newDataPct)  
        }) 
      } else {
          alert("Problem with bucketed ratings")
      }
    })
  }
  function DevDistrib() {
    fetch(props.api_url + '/statistics/bucketed_deviation?game='+props.game_type+'&offline='+offlineToggle, {
    })
    .then(res => {
      if (res.ok) {
        var retdata = [["A", "B"]];
        res.json().then((data) => {
          var edges = data['bin_edges'];
          var counts = data['bin_counts'];
          for (let i = 0; i < counts.length; i++) {
            retdata.push([edges[i], counts[i]]);
          }
          console.log("Setting deviation stat")
          const newData = devDist.map((entry, index) => {
            if (index === 0) {
                return entry;
            } else {
                const newProfit = retdata[index][1];
                return [entry[0], newProfit];
            }
        });
          setDevDist(newData)  
        }) 
      } else {
          alert("Problem with bucketed ratings")
      }
    })
  }

  function UpdateDistributions() {
    RatingDistrib();
    DevDistrib();
  }
  function GetCountries() {
    fetch(props.api_url + '/countries?game='+props.game_type, {
    })
    .then(res => {
      if (res.ok) {
        res.json().then((data) => {
          var ct = data['countries'];
          ct = ct.filter(n => n);
          ct.sort();
          setCountries(ct);
          console.log(countries);
        }) 
      } else {
          alert("Problem with getting countries")
      }
    })
  }
  async function OnMatchEntryClick(chartWrapper) {
    const chart = chartWrapper.getChart()
    const selection = chart.getSelection()
    console.log(selection);

    var opp_set = [];
    if(offlineToggle) {
      opp_set = opponentOfflineSlugs;
    }
    else {
      opp_set = opponentSlugs;
    }
    try {
      const res = await fetch(props.api_url + '/rating/'+opp_set[selection[0].row]+'?game='+pageInfo.game_type, {  });
      if(res.ok) {
        const data = await res.json();

        setOppData({
          rating: data["rating"],
          deviation: data["deviation"],
          gamertag: data["gamertag"],
          top_pct: Math.trunc((data["top_percent"]*10000))/100,
          winrate: Math.trunc((data["winrate"]*10000))/100,
          regional_percent: Math.trunc((data["regional_percent"]*10000))/100,
          regional_ranking: data["regionalranking"],
          global_ranking: data["globalranking"],
        });
      }else {
        alert("Could not find user")
      }
    } catch(error) {
      console.error("Error fetching data:" ,error);
      alert("An error occurred");
    }
    setOppShow(true);
  }

  async function OnH2HEntryClick(chartWrapper) {
    const chart = chartWrapper.getChart()
    const selection = chart.getSelection()
    console.log(headToHeadData);
    try {
      const res = await fetch(props.api_url + '/rating/'+headToHeadData[selection[0].row+1][0]+'?game='+pageInfo.game_type, {});
      if(res.ok) {
        const data = await res.json();

        setH2HOppModalData({
          rating: data["rating"],
          deviation: data["deviation"],
          gamertag: data["gamertag"],
          top_pct: Math.trunc((data["top_percent"]*10000))/100,
          winrate: Math.trunc((data["winrate"]*10000))/100,
          regional_percent: Math.trunc((data["regional_percent"]*10000))/100,
          regional_ranking: data["regionalranking"],
          global_ranking: data["globalranking"],
        });
        var i = 0;
        var indx = [];
        for(const entry in matchTableData) {
          if(data['gamertag'] === matchTableData[entry][1] && i !== 0){
            indx.push(i);
          }
          i = i + 1;
        }
        var matches_against = [["Match Time", "Opponent", "Final Rating", "Rating Change", "Deviation", "Tournament Name"]];
        
        indx.forEach(i => matches_against.push(matchTableData[i]));
        console.log(matches_against);
        setH2HModalMatchData(matches_against);
        setH2HModalWinRate(headToHeadData[selection[0].row+1][3])
      }else {
        alert("Could not find user")
      }
    } catch(error) {
      console.error("Error fetching data:", error);
      alert("An error occurred while fetching");
    }
    setH2HModalShow(true);
  }

  useEffect(() => {
    if(pageInfo.game_type === 't8') {
      var _paq = window._paq = window._paq || [];
      _paq.push(['trackPageView']);
      _paq.push(['enableLinkTracking']);
      (function() {
        var u="//tristanmelton.com/matomo/";
        _paq.push(['setTrackerUrl', u+'matomo.php']);
        _paq.push(['setSiteId', '2']);
        var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
        g.async=true; g.src=u+'matomo.js'; s.parentNode.insertBefore(g,s);
      })();
    }
    else if(pageInfo.game_type === '2xko') {
      var _paq = window._paq = window._paq || [];
      /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
      _paq.push(['trackPageView']);
      _paq.push(['enableLinkTracking']);
      (function() {
        var u="//tristanmelton.com/matomo/";
        _paq.push(['setTrackerUrl', u+'matomo.php']);
        _paq.push(['setSiteId', '3']);
        var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
        g.async=true; g.src=u+'matomo.js'; s.parentNode.insertBefore(g,s);
      })();
    }
    else if(pageInfo.game_type === 'sf6') {
      var _paq = window._paq = window._paq || [];
      /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
      _paq.push(['trackPageView']);
      _paq.push(['enableLinkTracking']);
      (function() {
        var u="//tristanmelton.com/matomo/";
        _paq.push(['setTrackerUrl', u+'matomo.php']);
        _paq.push(['setSiteId', '4']);
        var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
        g.async=true; g.src=u+'matomo.js'; s.parentNode.insertBefore(g,s);
      })();
    }


    UpdateDistributions()
    GetCountries()

    fetch(props.api_url + '/leaderboards/GL?game='+props.game_type+'&offline='+offlineToggle, {}) 
    .then(res => {
      if (res.ok) {
        var tbldata = [["Name", "Rating"]];
        res.json().then((data) => {
          var names = data['names'];
          var ratings = data['rating'];
    
          for (let i = 0; i < 10; i++) {    
            tbldata.push([names[i], ratings[i]])
          }
          console.log("Setting leaderboard data")
          const leaderboardChartData = new window.google.visualization.DataTable();
          leaderboardChartData.addColumn('string', 'Name');
          leaderboardChartData.addColumn('number', 'Rating');
          leaderboardChartData.addRows(tbldata.slice(1));
          const leaderboardChart = new window.google.visualization.ColumnChart(document.getElementById('leaderboardChart'));
      
          leaderboardChart.draw(leaderboardChartData, leaderboardBarOptions);  
          setLeaderboardTableData(tbldata);
        }) 
      } else {
      }    
    }) 
  }, []);
  useEffect(() => { 
    if(document.readyState === 'ready' || document.readyState === 'complete') {
      RenderMatchHistory();
      RenderCompStats();
      RenderLeaderboard();
    } 
    
  }, [bgcolor]);
  useEffect(() => {
    if(document.readyState === 'ready' || document.readyState === 'complete') {
      RenderLeaderboard();
    }
  }, [leaderboardTableData])
  useEffect(() => {
    if(offlineToggle){
      setRData(ratingOfflineData);
    } else {
      setRData(ratingData);
    }
  }, [ratingData, ratingOfflineData])
  useEffect(() => {
    RenderLeaderboard();
  }, [key])
// OBJECTS
          var tbldata = [["Name", "Rating"]];

  function Divider(props) {
    if(props.theme_context === 'light')
      return (
        <hr
          style={{
              color: 'black',
              backgroundColor: 'black',
              height: Number(props.thickness),
              marginTop: props.margin,
              marginBottom: props.margin,
          }}
        />
      )
    else
      return (
        <hr
          style={{
              color: 'white',
              backgroundColor: 'white',
              height: Number(props.thickness),
              marginTop: props.margin,
              marginBottom: props.margin,
          }}
        />
      )
  }
  function OpponentModal(props) {
    return (
      <Modal
        {...props}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        data-bs-theme={props.theme_context}
      >
        <Modal.Header closeButton style={{backgroundColor: props.bg_color}}>
          <Modal.Title id="contained-modal-title-vcenter" style={{color: props.font_color }}>
            Opponent Rating
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{backgroundColor: props.bg_color}}>
        <UserCard data={oppData} font_color={props.font_color} data-bs-theme={props.theme_context}/>
        </Modal.Body>
        <Modal.Footer style={{backgroundColor: props.bg_color}}>
          <Button onClick={props.onHide} data-bs-theme={props.theme_context}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  }
  function H2HModal(props) {
    return (
      <Modal
      {...props}
        aria-labelledby="contained-modal-title-vcenter"
        dialogClassName="h2h-modal"
      >
        <Modal.Header closeButton style={{backgroundColor: props.bg_color}}>
          <Modal.Title id="contained-modal-title-vcenter" style={{color: props.font_color}}>
            Head to Head Matchup Information
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{backgroundColor: props.bg_color}}>
        <Container>
          <Row style={{display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'center', margin: 'auto' }}>
            <Col style={{backgroundColor: '', display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'center', margin: 'auto' }}>
              <font size={5} color={props.font_color}>Rating Cards</font>
            </Col>
            <Col style={{backgroundColor: '', display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'center', margin: 'auto' }}> 
              <font size={5} color={props.font_color}>Match History</font>
            </Col>
          </Row>
          <Row style={{backgroundColor: '', display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'center', margin: 'auto' }}>
            <Col style={{backgroundColor: '', display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'center', margin: 'auto' }}>
            <Container>
              <Row style={{display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'center', margin: 'auto' }}>
                <UserCard data={ratingData} fontSize='3' cardWidth='100%' font_color={props.font_color}/>
              </Row>
              <Row style={{display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'center', margin: 'auto' }}>
                <UserCard data={h2hOppModalData} fontSize='3' cardWidth='100%' font_color={props.font_color}/>
              </Row>

            </Container>
            </Col>
            <Col>
              <Chart
                chartType="Table"
                width="100%"
                height="330px"
                data={h2hModalMatchData}
                options={h2hMatchHistoryOptions}
                formatters={matchTableFormatter}
                    />
            </Col>
          </Row>
          <Row style={{display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'center', margin: 'auto' }}>
            <Col style={{backgroundColor: '', display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'center', margin: 'auto' }}> 
            <font size={'5'} color={props.font_color}>Your Win Rate: {h2hModalWinRate}%</font>
            </Col>
          </Row>
        </Container>
        </Modal.Body>
        <Modal.Footer style={{backgroundColor: props.bg_color}}>
          <Button onClick={props.onHide} heading>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  }
  function Changelog(props) {
    return (
      <>
          <h3 style={{color: props.font_color}}>v1.0.9 (3/30/2025)</h3>
          <font color={props.font_color}>- Loading the page now starts on the "Leaderboards" tab. <br/></font>
          <font color={props.font_color}>- The "Leaderboards" tab now includes a bar plot of the top 10 of the selected region. On load, shows the Global top 10 of all (offline + online) events. <br/></font>
          <font color={props.font_color}>- The selected tab will automatically switch to the "Match History" tab when a player's rating is searched. <br/></font>
          <font color={props.font_color}>- Added "Show Offline Only" toggle switch to only show offline tournaments in the rating and match history sections. 
            When enabled, your match history and rating only take into account offline performance. <br/></font>
          <font color={props.font_color}>- Changed Rating History plot size to better fill out the space. <br/></font>
          <font color={props.font_color}>- Fixed some bugs with dark mode. <br/></font>
          <font color={props.font_color}>- Changelog moved to separate tab. <br/></font>

          <h3 style={{color: props.font_color}}>v1.0.8 (3/18/2025)</h3>
          <font color={props.font_color}>- Added dark mode (beta)! <br/> </font>

          <h3 style={{color: props.font_color}}>v1.0.7 (3/13/2025)</h3>
          <font color={props.font_color}>- Added additional head-to-head information when a row is clicked. This feature is in beta - please contact me to provide any feedback. <br/></font>
          
          <h3 style={{color: props.font_color}}>v1.0.6 (3/11/2025)</h3>
          <font color={props.font_color}>- Added "Opponent" Column to match history chart <br/></font>
          <font color={props.font_color}>- Clicking on a table row will bring up the opponent's rating information. <br/></font>
          
          <h3 style={{color: props.font_color}}>v1.0.5 (3/2/2025)</h3>
          <font color={props.font_color}>- Added set-tournament relation <br/></font>
          <font color={props.font_color}>- Added toggle to view match history plot with wins/loss colors or tournament-grouped colors <br/></font>
          <font color={props.font_color}>- Added labels to match history plot to designate the start and stop of each tournament. <br/></font>
          
          <h3 style={{color: props.font_color}}>v1.0.4 (1/23/2025)</h3>
          <font color={props.font_color}>- Updated backend for improved modularity for easier development. <br/></font>
          <font color={props.font_color}>- Reorganized figures into tabs. <br/></font>
          <font color={props.font_color}>- Added head-to-head tab that shows your win rates against other competitors. Sort by column by clicking the appropriate header. <br/></font>
          
          <h3 style={{color: props.font_color}}>v1.0.3 (1/15/2025)</h3>
          <font color={props.font_color}>- Added country-specific leaderboards! Search for your country and click it to see the best players in our country! <br/></font>
          <font color={props.font_color}>- Updated the backend to a faster and less memory-intensive library <br/></font>
          
          <h3 style={{color: props.font_color}}>v1.0.2 (1/5/2025)</h3>
          <font color={props.font_color}>- Updated Player Card to look nicer and be more presentable. <br/></font>
          <font color={props.font_color}>- Added Regional and Global rankings to the Player Card. <br/></font>
          <font color={props.font_color}>- Upgraded host to more RAM and further stabilized the backend. <br/></font>
          <font color={props.font_color}>- Upgraded backend for faster loading and resposne times. <br/></font>
          
          <h3 style={{color: props.font_color}}>v1.0.1 (1/4/2025)</h3>
          <font color={props.font_color}>- Added this changelog! <br/></font>
          <font color={props.font_color}>- Added a rating change column to the data table, with an arrow to indicate gain or loss at quick glance. <br/></font>
          <font color={props.font_color}>- Added color to sets in match history chart corresponding to a set win or set loss. <br/></font>
          <font color={props.font_color}>- Added chart zoom for easier viewing. Left click, hold, and draw a box to zoom in. Right click to reset zoom back to original. <br/></font>
          
          <h3 style={{color: props.font_color}}>v1.0.0</h3>
          <font color={props.font_color}>- Initial Release<br/></font>
        </>
    )
  }
  function UserCard(props) {
    return (
      <div className="UserInfo" style={{display:'flex', backgroundColor: '', width: props.cardWidth, border:'3px outset  black', 'border-radius': '5px', justifyContent: 'center',alignItems: 'center', margin: 'auto'}}>
        <Container style={{backgroundColor: ''}}>
          <Container fluid style={{backgroundColor: '', display:'flex', justifyContent: 'center'}}>
            <Row style={{display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'center', margin: 'auto' }}>
              <Col style={{backgroundColor: '', display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'center', margin: 'auto', color: props.font_color }}><h4>Username: {props.data['gamertag']}</h4></Col>
              <Col style={{backgroundColor: '', display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'center', margin: 'auto', color: props.font_color }}><h4>Rating: {props.data['rating']}</h4></Col>
            </Row>
          </Container>
        <Divider thickness="2" margin="3px"/>
          <Row style={{backgroundColor: ''}}>
            <Col>
              <font size={props.fontSize} color={props.font_color}>Win Rate: {props.data['winrate']}%</font>
            </Col>
            <Col>
              <font size={props.fontSize} color={props.font_color}>Regional Ranking: {props.data['regional_ranking']}</font>
            </Col>
            <Col>
              <font size={props.fontSize} color={props.font_color}>Regional Percentile: {props.data['regional_percent']}%</font>
            </Col>
          </Row>
          <Row>
            <Col>
              <font size={props.fontSize} color={props.font_color}>Deviation: {props.data['deviation']}</font>
            </Col>
            <Col>
              <font size={props.fontSize} color={props.font_color}>Global Ranking: {props.data['global_ranking']}</font>
            </Col>
            <Col >
              <font size={props.fontSize} color={props.font_color}>Global Percentile: {props.data['top_pct']}%</font>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }

  //<Form.Check // prettier-ignore
  //type="switch"
  //id="custom-switch"
  //label="Offline Only"
  //onClick={HandleMatchHistoryOfflineToggleChange}
  //data-bs-theme={props.theme_context}
  //style={{color: props.font_color}}
  ///>
  return (
    <>
      <head>
        <link rel="canonical" href={pageInfo.url} />
      </head>
      <h1 style={{backgroundColor: bgcolor, color:props.font_color, textAlign:'center', marginBottom: 0}}>{pageInfo.page_title}</h1>
      <div className="RatingsContent" style={{backgroundColor: bgcolor, flex:1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width:'100%', margin: 'auto'}}> 
        <div className="RatingsPage" style={{backgroundColor: bgcolor, width: '50%'}}>
          <div className="RatingsForm" style={{backgroundColor: '', width: '50%', justifyContent: 'center',alignItems: 'center', margin: 'auto'}} >
            <Form.Label htmlFor="ratingsapi" data-bs-theme={props.theme_context} style={{color: props.font_color}}>start.gg Gamer Tag or User Slug</Form.Label>
            <InputGroup className="mb-3" data-bs-theme={props.theme_context}>
              <InputGroup.Text id="basic-addon3">
              </InputGroup.Text>
              <Form.Control name="slug" aria-describedby="userinput-slug"
                onChange={HandleSlugChange}/>
              <Button variant="outline-secondary" id="rating-submit" onClick={(e) => RatingSubmit(e)} data-bs-theme={props.theme_context}>
                Submit
              </Button>
            </InputGroup>
            <Form.Text id="userinput-slug" class="w-100" muted data-bs-theme={props.theme_context}>
                This field is case-sensitive!
              </Form.Text>
          </div>
          <UserCard data={rData} fontSize='4' cardWidth='85%' data-bs-theme={props.theme_context} font_color={props.font_color}/>
        </div>
        <div className="Statistics" style={{backgroundColor:'', width:'50%'}}>
          <font size={3} color={props.font_color}>For all plots, drag a box to zoom, right click to reset.</font>
          <Divider thickness="5" theme_context={props.theme_context}/>
          <Tabs defaultActiveKey="leaderboards" id="alltabs" className="mb-3" activeKey={key} onSelect={(e) =>{setKey(e)}} fill data-bs-theme={props.theme_context}>
            <Tab eventKey="match_history" title="Match History" onEntered={RenderMatchHistory}  data-bs-theme={props.theme_context} >
              <Form data-bs-theme={props.theme_context}>
              
              <Form.Check // prettier-ignore
                  type="switch"
                  id="custom-switch"
                  label="Color by Tournament"
                  onClick={HandleMatchHistoryColorToggleChange}
                  data-bs-theme={props.theme_context}
                  style={{color: props.font_color}}
                />
                <Form.Check // prettier-ignore
                  type="switch"
                  id="custom-switch2"
                  label="Show Offline Only"
                  onClick={HandleMatchHistoryOfflineToggleChange}
                  data-bs-theme={props.theme_context}
                  style={{color: props.font_color}}
                />
              </Form>
              <Container data-bs-theme={props.theme_context}>
                <Row>
                <h2 style={{textAlign: 'center', color: props.font_color}}>Player Match History</h2>
                <Divider thickness="3" theme_context={props.theme_context}/>
                </Row>
                <Row>
                  <h3 style={{textAlign: 'center', color: props.font_color}}>Rating History</h3>
                </Row>
                <Row>
                  <div id='matchLineChart'></div>
                </Row>
                <Row>
                <Divider thickness="3" margin={20} theme_context={props.theme_context}/>
                </Row>
                <Row>
                  <Chart
                    chartType="Table"
                    width="100%"
                    height="200px"
                    data={currMatchData}
                    options={matchTableOptions}
                    formatters={matchTableFormatter}
                    chartEvents={[
                      {
                        eventName: 'select',
                        callback: ({ chartWrapper}) => {
                          OnMatchEntryClick(chartWrapper);                
                        },
                      },
                    ]}
                  />
                  <OpponentModal
                    show={oppShow}
                    onHide={() => setOppShow(false)}
                    bg_color = {props.bg_color}
                    font_color = {props.font_color}
                  />
                </Row>
                <Row style={{display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'center', margin: 'auto' }}>
                  <Col style={{backgroundColor: '', display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'center', margin: 'auto' }}>
                    <font size={'3'} color={props.font_color}>Click on an entry in the match history table to bring up the opponent's rating data. </font>
                  </Col>
                </Row>
              </Container>
            </Tab>
            <Tab eventKey="h2h" title="Head to Head" data-bs-theme={props.theme_context}>
              <Container>
                <Row>
                  <h2 style={{textAlign: 'center', color: props.font_color}}>Head to Head</h2>
                  <Divider thickness="3" margin="1" theme_context={props.theme_context}/>
                </Row>
                <Row>
                  <Chart
                    chartType="Table"
                    width="100%"
                    height="200px"
                    data={headToHeadData}
                    options={h2hTableOptions}
                    formatters={headToHeadFormatter}
                    chartEvents={[
                      {
                        eventName: 'select',
                        callback: ({ chartWrapper}) => {
                          OnH2HEntryClick(chartWrapper);                
                        },
                      },
                    ]}
                  />
                  <H2HModal
                    show={h2hModalShow}
                    onHide={() => setH2HModalShow(false)}
                    bg_color={props.bg_color}
                    font_color={props.font_color}
                  />
                </Row>
                <Row style={{display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'center', margin: 'auto' }}>
                  <Col style={{backgroundColor: '', display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'center', margin: 'auto' }}>
                    <font size={'3'} color={props.font_color}>Click on an entry in the head-to-head table to bring up user-specific statistics. </font>
                  </Col>
                </Row>

              </Container>            
            </Tab>
            <Tab eventKey="comp_stats" title="Competitor Statistics" onEntered={RenderCompStats} data-bs-theme={props.theme_context}>
              <Container>
                  <Row>
                    <h2 style={{textAlign: 'center', backgroundColor:'', color: props.font_color}}>Global Statistics</h2>
                    <Divider thickness="3" margin="1" theme_context={props.theme_context}/>
                  </Row>
                  <Row>
                    <Col >              
                      <div id='ratingDistChart'></div>
                    </Col>
                    </Row>
                    <Row>
                    <Col>
                      <div id='ratingDistPctChart'></div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div id='devDistChart'></div>
                    </Col>
                  </Row>
              </Container>  
            </Tab>
            <Tab eventKey="leaderboards" title="Leaderboards" data-bs-theme={props.theme_context}>
              <Container>
                <Row>
                  <h2 style={{textAlign: 'center', color: props.font_color}}>Leaderboards</h2>
                  <Divider thickness="3" margin="1" theme_context={props.theme_context}/>
                </Row>
                <Row>
                  <div id='leaderboardChart'></div>
                </Row>
                <Row>
                  <h3 style={{textAlign: 'center', color: props.font_color}}>Regional</h3>
                  <Divider thickness="1.5" margin="1" theme_context={props.theme_context}/>
                </Row>
                <Row>
                  <Form.Select aria-label="leaderboard-select" onChange={HandleLeaderboardRegionChange} data-bs-theme={props.theme_context}>
                    <option>Choose Region</option>
                    <option value="AM">Americas</option>
                    <option value="EU">Europe</option>
                    <option value="ME">Middle East</option>
                    <option value="AS">Asia</option>
                    <option value="OC">Oceania</option>
                    <option value="AF">Africa</option>
                    <option value="GL">Global</option>
                  </Form.Select>  
                </Row>
                <Row >
                  <Chart
                    chartType="Table"
                    width="100%"
                    height="200px"
                    data={leaderboardTableData}
                    options={leaderboardTableOptions}
                  />
                </Row>
                <Row>
                  <h3 style={{textAlign: 'center', color: props.font_color}}>Country</h3>
                  <Divider thickness="1.5" margin="1" theme_context={props.theme_context}/>
                </Row>
                <Row style={{backgroundColor:"", display:'flex', justifyContent: 'center',alignItems: 'center', margin: 'auto'}}>
                    <Dropdown as={ButtonGroup} onSelect={handleDropdownSelect} drop='down-centered' style={{width:"50%"}}>
                      <Dropdown.Toggle split variant="secondary" style={{borderRadius: "10px"}}>Select Country</Dropdown.Toggle>
                      <Dropdown.Menu className="custom-dropdown-menu" style={{overflowY: "scroll", maxHeight:"150px"}} >
                        <input 
                          type="text"
                          placeholder="Search..."
                          onChange={(e) => setCountrySearchTerm(e.target.value)}
                          style={{margin: "8px 10px", width: "90%"}}
                        />
                        {filteredOptions.map((country) => (
                          <Dropdown.Item key={country} eventKey={country}>{country}</Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                </Row>
                <Row>
                  <font size={3} color={props.font_color}>{currCountry}</font>
                  <Chart
                    chartType="Table"
                    width="100%"
                    height="200px"
                    data={countryLeaderboardTableData}
                    options={countryLeaderboardTableOptions}
                  />
                </Row>
              </Container>
            </Tab>
            <Tab eventKey="changelog" title="Changelog" data-bs-theme={props.theme_context}>
              <Container>
                <Row>
                  <h2 style={{textAlign: 'center', color: props.font_color}}>Changelog</h2>
                  <Divider thickness="3" margin="1" theme_context={props.theme_context}/>
                </Row>
                <Row>
                  <Changelog theme_context={props.theme_context} font_color={props.font_color}/>
                </Row>
              </Container>
            </Tab>
          </Tabs>
          <Divider thickness="5" theme_context={props.theme_context}/>
        </div>
      </div>
      <h5 style={{backgroundColor: bgcolor, textAlign: 'center', marginBottom: 0, color: props.font_color}}>Please contact me at tristan_fgc@proton.me if you face any problems or have sets / tournaments missing.</h5>
      <div style={{backgroundColor: bgcolor}}>
        <KoFiButton
          color="#00b4f7"
          id="tristanmelton"
          label="Support me on Ko-Fi"
          radius="12px"
        />
      </div>
    </>
  );
}

export default App;
